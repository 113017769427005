var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h1", [_vm._v("Cases")]),
      _c(
        "v-tabs",
        [
          _c(
            "v-tab",
            {
              on: {
                click: function ($event) {
                  return _vm.getOpenCases()
                },
              },
            },
            [_vm._v("Open Cases")]
          ),
          _c(
            "v-tab",
            {
              on: {
                click: function ($event) {
                  return _vm.getClosedCases()
                },
              },
            },
            [_vm._v("Closed Cases")]
          ),
          _c("v-tab-item", [_c("open-cases", { ref: "openCases" })], 1),
          _c("v-tab-item", [_c("closed-cases", { ref: "closedCases" })], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }