<template>
  <div>
    <h1>Cases</h1>
    <v-tabs>
      <v-tab @click="getOpenCases()">Open Cases</v-tab>
      <v-tab @click="getClosedCases()">Closed Cases</v-tab>
      <!-- Open Cases -->
      <v-tab-item>
        <open-cases ref="openCases"></open-cases>
      </v-tab-item>
      <!-- Closed Cases -->
      <v-tab-item>
        <closed-cases ref="closedCases"></closed-cases>
      </v-tab-item>
    </v-tabs>  
    <!-- :style="{ height: '440px' }" -->

  </div>
</template>

<script>
import ClosedCases from './ClosedCases.vue'
import OpenCases from './OpenCases.vue'
import Service from "@/services/Service.js";

export default {
  components: { 
    'open-cases': OpenCases, 
    'closed-cases': ClosedCases
  },
  data: function () {
    return {
      open_cases: [],
    };
  },
  watch: {
    '$route.params.id': {
      handler: function(value) {
        this.$refs.openCases.loadCaseFromSearch(this.$refs.openCases.gridData);
        //this.$router.go(0);
      },
      deep: true,
    }
  },
  methods: {
    getClosedCases() {
      this.$refs.closedCases.getData();
    },
    getOpenCases() {
      this.$refs.openCases.getData();
      // Service.getCasesNotDone()
      //   .then((response) => {
      //     this.open_cases = response.data;
      //   })
      //   .catch((error) => {
      //     Vue.$toast.error(`Error loading data`, { position: "top-right" });
      //     console.log(error);
      //   });
    },
  },
  created: function () {
    // this.getOpenCases();
  }
}
</script>

<style>
</style>